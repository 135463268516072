import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { authData } from "../../../State";

interface mobileProps {
  close: any;
}

const MobileView = ({ close }: mobileProps) => {
  const router = useRouter();

  const { loggedIn } = useRecoilValue(authData);

  return (
    <Flex
      h="100%"
      flexDir="column"
      px={6}
      pb={3}
      // borderTopWidth="3px" borderColor="blue.500"
    >
      <Fragment>
        {/* Logo and close icon */}

        {/* List of navigation items */}
        <VStack align="left" spacing="6" pb={6} textAlign="left" fontSize="lg" fontWeight={700} color="gray.500">
          {/* <Link href="/discover">Discover Classes</Link> */}
          {/* <Link href="/e">Events</Link> */}
          {/* <Link href="/blog">Blog</Link> */}
          {/* <Link href="/our-story">Our Story</Link> */}
          {/* <Link href="/faq">FAQ</Link> */}
          {/* <Link href="/pricing">Pricing</Link> */}
        </VStack>
      </Fragment>

      <Box
      // mt="auto"
      >
        <Button
          fontSize="lg"
          size="lg"
          px={5}
          fontWeight={700}
          colorScheme="blue"
          borderRadius="lg"
          w="100%"
          onClick={() => {
            if (!loggedIn) {
              router.push("/login");
            } else {
              router.push("/classes/enrolled");
            }
          }}>
          {loggedIn ? "My Classes" : "Login"}
        </Button>

        {/* <Button
          w="100%"
          mt={2}
          variant="outline"
          fontSize="sm"
          size="lg"
          px={5}
          fontWeight={700}
          colorScheme="blue"
          borderRadius="lg"
          onClick={() => (window.location.href = "https://app.learntific.com/")}>
          Create a Class
        </Button> */}

        <Text textAlign="center" fontSize="sm" mt={4} color="gray.400" fontWeight="bold">
          Learntific ©2021 - Till Date.
        </Text>
      </Box>
    </Flex>
  );
};

export default MobileView;
