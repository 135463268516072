import React from "react";
import {
  Drawer,
  DrawerBody,
  // DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  // DrawerCloseButton
} from "@chakra-ui/react";

interface DrawerProps {
  disclosure: any;
  btnRef: any;
  placement?: any;
  children?: any;
  size?: string;
  isFullHeight?: boolean;
  title?: any;
  height?: any;
  maxHeight?: any;
  borderTopRadius?: any;
  overflowY?: any;
}

const CustomDrawer = ({
  disclosure,
  borderTopRadius,
  btnRef,
  height,
  placement,
  children,
  size,
  isFullHeight,
  title,
  overflowY = "auto",
  maxHeight,
}: DrawerProps) => {
  return (
    <Drawer
      size={size}
      isOpen={disclosure.isOpen}
      placement={placement}
      onClose={disclosure.onClose}
      finalFocusRef={btnRef}
      isFullHeight={isFullHeight}
    >
      <DrawerOverlay bg="rgba(255, 255, 255, .8)">
        <DrawerContent
          overflowY={overflowY}
          boxShadow="md"
          borderWidth="1px"
          height={height}
          maxHeight={maxHeight}
          borderTopRadius={borderTopRadius}
        >
          {/* <DrawerCloseButton p={0} m={0} /> */}

          {title && <DrawerHeader px={0}>{title}</DrawerHeader>}

          <DrawerBody overflowY={overflowY} p={0}>
            {children}
          </DrawerBody>

          {/* <DrawerFooter>
						<Button variant="outline" mr={3} onClick={disclosure.onClose}>
							Cancel
						</Button>
						<Button color="blue">Save</Button>
					</DrawerFooter> */}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CustomDrawer;
