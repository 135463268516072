import { Avatar, Box, Button, Center, chakra, Flex, HStack } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useRecoilValue } from "recoil";
import { authData } from "../../../State";
interface Props {
  data?: any;
}

const LinkComponent = ({ title, path }) => {
  const router = useRouter();
  const condition = router.pathname === path;

  return (
    <Link passHref href={path}>
      <Center pos="relative" h="74px" px={3} cursor="pointer" color={condition && "blue.500"}>
        {title}
        <Box pos="absolute" bottom={0} left={0} w="100%" h="4px" bg={condition && "blue.500"} borderTopRadius="2px"></Box>
      </Center>
    </Link>
  );
};

const LandingView = ({ data }: Props) => {
  const router = useRouter();
  // recoil
  const { loggedIn } = useRecoilValue(authData);
  return (
    <Flex w="100%">
      <Box my="auto" cursor="pointer">
        <Link href="/">
          <chakra.svg
            w="auto"
            h="32px"
            color={{ base: router.pathname.startsWith("/c/") || router.pathname.startsWith("/discover") ? "black" : "white", md: "white" }}
            mx="auto"
            viewBox="0 0 364.213 89.211">
            <path
              d="M16.068,0,0,14.226V67.248L21.963,89.211H74.985L91.053,74.985H38.031L16.607,53.561Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M16.068,0,0,14.226V67.248L21.963,89.211H74.985L91.053,74.985H38.031L16.607,53.561Zm-4.31,9.7L4.409,16.211V65.422L23.789,84.8H73.314l6.11-5.409H38.031A4.413,4.413,0,0,1,34.914,78.1L13.489,56.678a4.409,4.409,0,0,1-1.29-3.073Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M16.069,53.024V0H79.351l11.7,10.712V74.986H38.032ZM23.783,7.715V49.829L41.227,67.271H83.339V14.111l-6.986-6.4Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path d="M51.456,26.8H41.272V49.4h23V39.819H51.456Z" fill="currentColor" />
            <path
              d="M191.328,37.234l-.455,3.459c-1.587-2.608-5.386-4.365-9.412-4.365-7.882,0-13.382,5.5-13.382,14.629,0,8.959,4.877,15.423,13.325,15.423a11.505,11.505,0,0,0,9.243-4.423l.453,3.629h8.223V37.234Zm-.8,14.176c0,4.253-2.835,7.088-6.917,7.088-4.14,0-6.748-2.892-6.748-7.088s2.608-7.087,6.748-7.087C187.7,44.323,190.533,47.158,190.533,51.41Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M280.918,57.988a9.441,9.441,0,0,1-2.892.51c-2.041,0-3.459-1.077-3.459-4.649V44.493h6.294V37.234h-6.294V28.446h-8.732v8.788h-4.309v7.259h4.309v11.17c0,7.938,4.083,10.6,9.923,10.6a14.452,14.452,0,0,0,6.181-1.248Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <g style={{ isolation: "isolate" }}>
              <g style={{ isolation: "isolate" }}>
                <path d="M116.309,57.081h16.556v8.5H107.123V23.627h9.186Z" fill="currentColor" />
              </g>
              <g style={{ isolation: "isolate" }}>
                <path
                  d="M135.475,51.354c0-8.846,5.953-15.026,14.515-15.026,8.9,0,14.459,5.84,14.459,15.082v2.212l-20.583.113c.511,3.743,2.609,5.5,6.465,5.5,3.288,0,5.613-1.19,6.237-3.232h7.995c-1.021,6.294-6.635,10.32-14.4,10.32C141.6,66.323,135.475,60.086,135.475,51.354Zm8.5-3.005h11.794c0-3-2.211-4.933-5.727-4.933C146.588,43.416,144.66,44.946,143.98,48.349Z"
                  fill="currentColor"
                />
              </g>
              <g style={{ isolation: "isolate" }}>
                <path
                  d="M225.4,45.457h-2.778c-4.989,0-8,2.211-8,7.824V65.586H205.9V37.292h8.221l.453,4.2a7.605,7.605,0,0,1,7.485-4.65,14.505,14.505,0,0,1,3.345.4Z"
                  fill="currentColor"
                />
              </g>
              <g style={{ isolation: "isolate" }}>
                <path
                  d="M229.829,65.586V37.235h8.222l.566,2.949c1.758-2.438,4.991-3.856,8.676-3.856,6.747,0,10.66,4.309,10.66,11.737V65.586h-8.732V50.163c0-3.572-1.985-5.9-4.989-5.9-3.459,0-5.671,2.268-5.671,5.784V65.586Z"
                  fill="currentColor"
                />
              </g>
              <g style={{ isolation: "isolate" }}>
                <path
                  d="M284.887,27.823a4.962,4.962,0,1,1,4.989,4.99A4.913,4.913,0,0,1,284.887,27.823Zm.681,9.412H294.3V65.586h-8.731Z"
                  fill="currentColor"
                />
              </g>
              <path
                d="M321.289,44.493H310.857V65.586h-8.733V44.493h-3.912V37.235h3.912V34.967c0-8.448,4.366-12.134,11-12.134a24.308,24.308,0,0,1,3.912.34v7.485H314.6c-3.006,0-3.742,1.871-3.742,4.309v2.268h19.221V65.586h-8.789Zm-.567-16.67a4.962,4.962,0,1,1,4.99,4.99A4.913,4.913,0,0,1,320.722,27.823Z"
                fill="currentColor"
              />
              <path
                d="M349.7,36.328c8.392,0,13.949,4.593,14.516,12.021h-8.789c-.623-2.835-2.381-4.2-5.387-4.2-3.685,0-6.066,2.778-6.066,7.144,0,4.536,2.211,7.2,5.953,7.2,3.062,0,4.934-1.417,5.5-4.195h8.789c-.567,7.143-6.351,12.02-14.289,12.02-8.959,0-14.742-5.954-14.742-15.026C335.182,42.565,341.193,36.328,349.7,36.328Z"
                fill="currentColor"
              />
            </g>
          </chakra.svg>
        </Link>
      </Box>
      {/* Middle items */}
      <HStack d={{ base: "none", lg: "flex" }} spacing={4} fontSize="lg" pt="4px" fontWeight={700} color="gray.500" mx="auto">
        {/* <Link href="/e">Events</Link> */}
        {/* <LinkComponent title="Events" path="/e" /> */}
        {/* <LinkComponent title="Blog" path="/blog" /> */}
        {/* <LinkComponent title="Our Story" path="/our-story" /> */}
        {/* <LinkComponent title="FAQ" path="/faq" /> */}
        {/* <LinkComponent title="Pricing" path="/pricing" /> */}
        {/* <Link href="/blog">Blog</Link>
        <Link href="/our-story">Our Story</Link>
        <Link href="/faq">FAQ</Link>
        <Link href="/pricing">Pricing</Link> */}
      </HStack>
      {loggedIn ? (
        <Button
          d={{ base: "none", md: "flex" }}
          variant="ghost"
          fontSize="md"
          size="lg"
          borderRadius="full"
          pl={2}
          pr={4}
          onClick={() => {
            router.push("/classes/enrolled");
          }}
          fontWeight={700}
          bg="blue.50"
          colorScheme="blue"
          color="blue.500"
          my="auto">
          <Avatar name="" src={data.avatar} bg="blue.500" w="32px" h="32px" size="sm" borderRadius="full" fontWeight="bold" mr={2} />
          My Classes
        </Button>
      ) : null}
      {!loggedIn && (
        <Box my="auto" ml={{ base: "auto", lg: 0 }}>
          <Link passHref href="/discover">
            <Button
              d={{ base: "none", md: "inline-flex" }}
              size="lg"
              colorScheme="light"
              as="a"
              mr={{ base: 2, md: 4 }}
              minH="52px"
              variant="ghost"
              _hover={{ bg: "blue.400" }}>
              Join a Class
            </Button>
          </Link>
          <Link passHref href="/login">
            <Button
              as="a"
              variant="outline"
              size="lg"
              colorScheme="gray"
              color="white"
              borderColor="white"
              _hover={{ bg: "white", color: "black", borderColor: "white" }}>
              Login
            </Button>
          </Link>
        </Box>
      )}
    </Flex>
  );
};
export default LandingView;
