import Cookie from "js-cookie";
import { atom } from "recoil";
import { PaymentStatus } from "../shared/types";

const studentDataOriginal = Cookie.get("x-auth-student");
const parsedStudentData = studentDataOriginal
  ? JSON.parse(studentDataOriginal)
  : {};

const getToken = () => {
  const token = Cookie.get("x-auth-token");
  return token;
};

export const classToJoin = atom({
  key: "classToJoin",
  default: {
    _id: "",
    class_name: "",
    class_code: "",
    class_description: "",
    avatar: "",
    is_private: "",
    created_by: {
      full_name: "",
      avatar: "",
    },
    students: {
      student: {
        _id: "",
        full_name: "",
      },
      date_joined: "",
    },
    lessons: {
      _id: "",
      lesson_name: "",
      lesson_content: "",
      objectives: "",
      has_uploaded_video: "",
    },
    referral_link: null,
  },
});

export const authData = atom({
  key: "authData",
  default: {
    loggedIn: !!getToken(),
    studentData: {
      ...parsedStudentData,
    },
  },
});

export const newReply = atom({
  key: "newReply",
  default: {
    refTest: null,
    commentId: "",
    user: null,
    message: "",
    type: "",
  },
});

export const activeClass = atom({
  key: "activeClass",
  default: {
    active_class_name: "",
    active_class_id: "",
  },
});

export const IndustryList = atom({
  key: "industries",
  default: [
    "Media and Entertainment",
    "Programming and Data Science ",
    "Finance",
    "Design and Content Creation ",
    "AI and Robotics",
    "Leadership and Politics",
    "Medicine and Pharmaceutical Science",
    "Finance and Accounting",
    "Tertiary Institution",
    "Secondary School/K12",
    "Universal Basic Education",
    "Fashion Design and Tailoring",
    "Marketing and Advertising",
    "Agriculture and Farming",
    "Corporate and Leadership ",
    "Vocational Skills Development",
    "Food, Catering and Nutrition",
    "Entrepreneurship and Business Dev.",
    "Hospitality and Tourism ",
    "Telecom and Networking",
    "Mining and Manufacturing",
    "Real Estate and Management",
    "Others",
  ],
});

export const filterData = atom({
  key: "filter",
  default: {
    value: "All",
    checked: true,
  },
});

export const trackJoinClass = atom({
  key: "trackJoinClass",
  default: "None",
});

export const classRefetch = atom({
  key: "classRefetch",
  default: {
    refetchClasses: null,
  },
});

export const lessonContentSwitch = atom({
  key: "lessonContentSwitch",
  default: {
    fontType: "",
    fontSize: "",
    bg: "",
  },
});

export const ClassLessonIndex = atom({
  key: "ClassLessonIndex",
  default: 0,
});

export const currentCardIndex = atom({
  key: "currentCardIndex",
  default: 0,
});

export const allClasses = atom({
  key: "allClasses",
  default: null,
});

export const paymentStatus = atom<PaymentStatus>({
  key: "paymentStatus",
  default: "idle",
});
// getToken.getInitialProps = async (ctx) => {
//   const token = Cookie.get("x-auth-token");
//   return { token };
// };
