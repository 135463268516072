// Libraries
import { Box, Button, chakra, Flex, InputGroup } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
// Recoil
import { useRecoilValue } from "recoil";
import { authData } from "../../../State";

// import { HiOutlineSearch } from "react-icons/hi";

const MainView = () => {
  const router = useRouter();

  const { loggedIn } = useRecoilValue(authData);

  return (
    <Flex w="100%">
      {/* Logo on medium screens */}
      <Box d={{ base: "none", md: "inline" }} my="auto" cursor="pointer">
        <Link href="/">
          <chakra.svg w="48px" color={{ base: "black", md: "white" }} h="auto" viewBox="0 0 512 512">
            <path
              d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28ZM123.156,118.7,94.247,144.3V337.89l76.239,76.242H365.309l24.04-21.281H226.512a17.355,17.355,0,0,1-12.263-5.08l-84.28-84.28a17.338,17.338,0,0,1-5.076-12.088Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M140.116,289.117V80.531H389.058L435.1,122.672V375.511H226.516Zm30.348-178.238V276.547l68.622,68.616H404.749V136.037l-27.484-25.158Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path d="M279.324,185.949H239.263v88.892H329.75V237.172H279.324Z" fill="currentColor" />
          </chakra.svg>
        </Link>
      </Box>

      {/* Logo on mobile */}
      <Box d={{ md: "none" }} my="auto" cursor="pointer">
        <Link href="/">
          <chakra.svg w="50px" h="auto" viewBox="0 0 512 512" color="black">
            <path
              d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M140.114,80.528,76.9,136.492V345.073l86.4,86.4H371.884l63.21-55.964H226.512l-84.28-84.28ZM123.156,118.7,94.247,144.3V337.89l76.239,76.242H365.309l24.04-21.281H226.512a17.355,17.355,0,0,1-12.263-5.08l-84.28-84.28a17.338,17.338,0,0,1-5.076-12.088Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path
              d="M140.116,289.117V80.531H389.058L435.1,122.672V375.511H226.516Zm30.348-178.238V276.547l68.622,68.616H404.749V136.037l-27.484-25.158Z"
              fill="currentColor"
              fillRule="evenodd"
            />
            <path d="M279.324,185.949H239.263v88.892H329.75V237.172H279.324Z" fill="currentColor" />
          </chakra.svg>
        </Link>
      </Box>

      {/* Middle items */}
      {/* Search bar */}
      <InputGroup d={{ base: "none", md: "flex" }} my="auto" mx={32}>
        {/* <InputLeftElement
					my="auto"
					pointerEvents="none"
					children={
						<Text fontSize="lg">
							<HiOutlineSearch />
						</Text>
					}
				/>
				<Input
					my="auto"
					fontWeight="bold"
					_focus={{ outline: 'none', boxShadow: 'none' }}
					_placeholder={{ color: 'gray.500' }}
					variant="flushed"
					type="phone"
					placeholder="Search"
				/> */}
      </InputGroup>

      {/* Login Button and Create a class Button */}
      <Flex my="auto">
        {/* CReate a class button */}
        {/* <Button
          d={{ base: "none", md: "flex" }}
          variant="outline"
          fontSize="sm"
          size="lg"
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location.href = "https://app.learntific.com/";
            }
          }}
          border="none"
          px={6}
          fontWeight={700}
          colorScheme="blue"
          bg="primary.50"
          _hover={{
            bg: "primary.100",
          }}
          _active={{
            bg: "primary.200",
          }}
          borderRadius="lg"
          my="auto">
          Create a Class
        </Button> */}

        {/* login button */}
        {!loggedIn && (
          <Button
            d={{ base: "none", md: "flex" }}
            onClick={() => router.push("/login")}
            variant="outline"
            fontSize="lg"
            size="lg"
            px={6}
            ml={2}
            fontWeight={700}
            colorScheme="blue"
            borderRadius="lg"
            my="auto">
            Login
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default MainView;
