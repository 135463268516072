// Graphql
import { useApolloClient } from "@apollo/client";
// Libraries
import { Avatar, Button, chakra, Container, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from "@chakra-ui/react";
// firebase
import firebase from "firebase/app";
import "firebase/auth";
import Cookie from "js-cookie";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { useGoogleLogout } from "react-google-login";
import { HiChevronLeft } from "react-icons/hi";
// Recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authData } from "../../../State";
import CustomDrawer from "../Drawer";
// Components
import LandingView from "./LandingView";
import MainView from "./MainView";
import MobileView from "./MobileView";

interface navProps {
  position?: string;
}

const Navbar = ({ position }: navProps) => {
  const navDisclosure = useDisclosure();
  const client = useApolloClient();
  // recoil
  const { loggedIn, studentData } = useRecoilValue(authData);
  const setAuthValue = useSetRecoilState(authData);
  const btnRef = React.useRef();
  const router = useRouter();
  const type = Cookie.get("x-auth-type");
  const uName = Cookie.get("student_name");
  //==================== Logout =========================
  const removeToken = async () => {
    await Cookie.remove("x-auth-token");
    await Cookie.remove("x-auth-student");
    await Cookie.remove("x-auth-type");
    setAuthValue({
      loggedIn: false,
      studentData: {},
    });
    // router.push("/login");
    if (typeof window !== "undefined") {
      window.location.href = "/login";
    }
  };
  // Callback function for google
  const onLogoutSuccess = async () => {
    removeToken();
    client.clearStore();
    return;
  };
  // Google logout, clientId is our own personal google id and onLogoutSUccess is the callback function above
  const clientId = "540878263087-vib1pfgotojd3rkp73tob067d3mj534f.apps.googleusercontent.com";
  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
  });
  const logoutHandler = async () => {
    if (type === "email") {
      removeToken();
    }
    if (type === "google") {
      signOut();
      firebase.auth().signOut();
      removeToken();
      client.clearStore();
    }
  };
  // ======================================================
  const showNav = router.pathname.startsWith("/classes/enrolled/");
  return (
    <>
      <chakra.nav
        zIndex={1300}
        flexDir="column"
        d={showNav ? "none" : "flex"}
        w="100%"
        minHeight="80px"
        bg={{ base: router.pathname !== "/" ? "white" : "black", md: "black" }}>
        <Container maxW={{ md: "98vw" }} d="flex" my="auto" px={{ base: 6, lg: "1rem" }} alignItems="center">
          {/* This shows when you're not in auth pages like login, signup, forgot and reset password */}
          {position !== "auth" ? (
            <Fragment>
              {router.pathname === "/" || router.pathname.startsWith("/c/") || router.pathname.startsWith("/discover") ? (
                <LandingView data={studentData} />
              ) : (
                <MainView />
              )}
              {
                loggedIn && (
                  <Fragment>
                    <Flex d={{ md: "none" }} ml="auto" my="auto">
                      <Menu>
                        <MenuButton>
                          <Avatar
                            name={studentData.full_name || uName}
                            src={studentData.avatar}
                            w="54px"
                            h="54px"
                            size="sm"
                            fontWeight="bold"
                            borderRadius="full"
                          />
                        </MenuButton>
                        <MenuList p={2}>
                          <Link href="/classes/enrolled">
                            <MenuItem fontWeight="500">My Classes</MenuItem>
                          </Link>
                          <MenuItem fontWeight="500" onClick={() => logoutHandler()}>
                            Logout
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Fragment>
                )
                // <Fragment>
                //   {/* Hamburger icon that toggles mobile drawer */}
                //   <Text onClick={navDisclosure.onOpen} ref={btnRef} ml="auto" cursor="pointer" my="auto" d={{ base: "block", md: "none" }}>
                //     <svg width="38" height="11" viewBox="0 0 38 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                //       <path d="M37 1H1M1 10.1139H37" stroke="#005EE9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                //     </svg>
                //   </Text>
                // </Fragment>
              }
            </Fragment>
          ) : (
            <Button
              variant="solid"
              fontSize="lg"
              size="lg"
              border="none"
              pl={3}
              pr={6}
              onClick={() => router.push("/")}
              fontWeight={700}
              colorScheme="gray"
              borderRadius="14px"
              my="auto"
              mr={2}>
              <Text fontSize="xl" as="span" mr={1}>
                <HiChevronLeft size="24px" />
              </Text>
              <Text as="span">Go Back</Text>
            </Button>
          )}
        </Container>
      </chakra.nav>
      {/* Drawer that shows on mobile screens */}
      <CustomDrawer
        placement="bottom"
        size="xl"
        height="70vh"
        borderTopRadius="16px"
        disclosure={navDisclosure}
        btnRef={btnRef}
        title={
          <Flex px={4} height="35px">
            <Text cursor="pointer" onClick={navDisclosure.onClose} m="auto">
              <chakra.svg width="32px" height="32px" fill="none" stroke="gray.400" viewBox="0 0 24 24" id="chevron-down">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </chakra.svg>
            </Text>
          </Flex>
        }>
        <MobileView close={navDisclosure.onClose} />
      </CustomDrawer>
    </>
  );
};
export default Navbar;
